import React from "react";
import cx from "classnames";

const SupportingBlock = ({ donationBlock }) => {
  return (
    <div className="font-supply uppercase px-gutter mt-[200px] pb-36 ">
      <h2 className="text-[48px] text-center mb-14">
        Charities we're supporting
      </h2>
      <div className="text-[29px] flex flex-col lg:flex-row justify-between max-w-[1500px] mx-auto mb-20 text-center lg:text-left space-y-10 lg:space-y-0">
        <div>
          <p>gisborne / tairawhiti / east coast</p>
          <a
            href="https://matai.org.nz/"
            target="_blank"
            className="underline underline-offset-4"
          >
            matai charitable trust
          </a>
        </div>
        <div>
          <p>hawke's bay</p>
          <a
            href="https://forms.office.com/pages/responsepage.aspx?id=4XjhUiHkek2WgP9gObxCl_yaK0FziStHpJJnNJ54LzJUOUdFNU5VODZOVlhEODk5RjM0TlNFOU9HTy4u"
            target="_blank"
            className="underline underline-offset-4"
          >
            evergreen foundation
          </a>
        </div>
      </div>
    </div>
  );
};

export default SupportingBlock;
