import React, { useState, useEffect, useRef } from 'react';

import { Link } from 'gatsby';
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import HeaderText from '../components/donationForm/headerText';
import PrizeGrid from '../components/donationForm/prizeGrid';
import SupportingBlock from '../components/donationForm/supportingBlock';
import TermsPopup from '../components/donationForm/termsPopup';
import PortalModal from '@components/UI/portalModal';
import { useCountUp } from 'react-countup';

const IndexPage = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  // const [donationAmount, setDonationAmount] = useState(0);

  // async function getDonationAmount() {
  //   const res = await fetch("/.netlify/functions/get-donation-amount");
  //   const data = await res.json();
  //   return data;
  // }

  // useEffect(async () => {
  //   setDonationAmount(await getDonationAmount());
  // }, []);

  // useEffect(() => {
  //   update(donationAmount);
  // }, [donationAmount]);

  const countUpRef = useRef();

  const { start, reset, update } = useCountUp({
    ref: countUpRef,
    start: 300000,
    end: 604000,
    duration: 2,
    separator: ',',
    prefix: '$',
    suffix: '.00',
    // useEasing: true,
    // onReset: () => console.log("Resetted!"),
    // onUpdate: () => console.log("Updated!"),
    // onPauseResume: () => console.log("Paused or resumed!"),
    // onStart: ({ pauseResume }) => console.log(pauseResume),
    // onEnd: ({ pauseResume }) => console.log(pauseResume),
  });

  return (
    <Layout setOpen={setOpen}>
      <PageMeta />
      <HeaderText />
      <div className='flex flex-col-reverse lg:flex-row px-gutter lg:space-x-10 mt-20 lg:mt-5'>
        <div className='basis-[100%] h-full w-full flex flex-col self-end'>
          <div className='font-supply items-center lg:items-end h-full flex flex-col  pt-10 lg:pt-0'>
            <p className='text-[24px] lg:text-[28px] uppercase'>
              Amount raised to date:
            </p>
            <p className='text-[40px] lg:text-[48px] ' ref={countUpRef}>
              {/* $
              {donationAmount.toLocaleString("nz-EN", {
                minimumFractionDigits: 2,
              })} */}
            </p>
          </div>
        </div>
        <div className='basis-[100%] font-circular-book'>
          <p>
            Congratulations to all our winners.
            <br />
            <br />
            Thanks to the generosity of the various businesses and partners who
            have contributed. 100% of all funds raised has been distributed
            evenly between the{' '}
            <a
              href='https://forms.office.com/pages/responsepage.aspx?id=4XjhUiHkek2WgP9gObxCl_yaK0FziStHpJJnNJ54LzJUOUdFNU5VODZOVlhEODk5RjM0TlNFOU9HTy4u'
              target='_blank'
              className='underline underline-offset-4'
            >
              Hawkes Bay Evergreen Foundation
            </a>{' '}
            and{' '}
            <a
              href='https://matai.org.nz/'
              target='_blank'
              className='underline underline-offset-4'
            >
              The Gisborne / Tairawhiti / East Coast Matai Charitable Trust
            </a>
            . Both organisations are directly working on the ground to help
            those communities most impacted.
            <br />
            <br />
            Thanks for your support, it means a lot.
          </p>
        </div>
      </div>
      {/* <PrizeGrid setOpen={setOpen} /> */}
      <SupportingBlock />
      {/* terms modal starts */}
      {/* <PortalModal isShowing={open}>
        <TermsPopup handleClose={handleClose} />
      </PortalModal> */}
    </Layout>
  );
};

export default IndexPage;
