import React from "react";

const HeaderText = () => {
  return (
    <div className="uppercase font-supply text-[32px] sm:text-[80px] xl:text-[120px] max-w-[1500px] mx-auto leading-[1.2em]">
      <h2 className="pr-20 text-right">Hawkes Bay,</h2>
      <h2 className="pl-10">Gisborne & East</h2>
      <h2>Coast Flood Relief</h2>
      <h2 className="pl-20 lg:pl-60 underline underline-offset-8 decoration-4 ">
        Raffle
      </h2>
    </div>
  );
};

export default HeaderText;
